import TipPopover from './tip-popover'

const ReturnTipPopover = (props) => {
  return (<TipPopover {...props}>
    <div>
      <h5>Return</h5>
      <p>Return is the side of a channel letter.Returns sizes range from 3-5 inches</p>
      <img src="https://user-images.githubusercontent.com/99938590/155649247-1914aace-8169-4da4-a9b9-a60395a2873e.png"></img>
    </div>
  </TipPopover>)
}

export default ReturnTipPopover;