import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Intent } from '@blueprintjs/core';

const LogoutButton = () => {
  const { logout, isAuthenticated } = useAuth0();

  return ( isAuthenticated &&
    <Button intent={Intent.WARNING} onClick={() => logout({ returnTo: window.location.origin })} text="Log Out" />
  );
};

export default LogoutButton;