import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'polotno/model/store';
import { unstable_setRemoveBackgroundEnabled,  } from 'polotno/config';
import { updatePoints, addOrUpdateHorizontalRuler, addOrUpdateVerticalRuler, resizeCanvas, recenterObjects } from './utils/ziyad-functions';
import { autorun, makeAutoObservable } from 'mobx';
import { getConfig } from "./config";
import { throttle } from 'lodash'

import { unstable_registerToolbarComponent } from 'polotno/config';

import './index.css';
import App from './App';

import { Auth0Provider } from "@auth0/auth0-react";
import './elements/text-plus-element';
import { FONTS } from './constants/fonts'


// Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getConfig();
// Overwrite Text toolbar to hide properties selection
const TextToolbar = ({ store }) => { return(null) };
unstable_registerToolbarComponent('text', TextToolbar);


// unstable_setRemoveBackgroundEnabled(true);

const store = createStore({ key: 'nFA5H9elEytDyPyvKL7T', showCredit: false });

window.store = store;

// load used fonts into Canvas. This is needed for text-plus element
FONTS.forEach( font => store.loadFont(font))

autorun(() => {
  if (store.selectedElements && store.selectedElements.length === 1) {
    store.openSidePanel('edit-section');
  }
});

store.on('change', () => {
  try {

    if (store.activePage) {
      const points = updatePoints(store);
      const { left, right, bottom, top } = points;

      // throttle Canvas element updates for performance
      // TODO: move to element's onDragEnd methods so that it's only called once.
      const throttledCanvasUpdate = throttle( () => {
        addOrUpdateHorizontalRuler(store, left, right);
        addOrUpdateVerticalRuler(store, top, bottom);
        recenterObjects(store, points);
        resizeCanvas(store, points);
      }, 200)
      throttledCanvasUpdate();
    }
   
  } catch (e) {
    console.error(e)
  }
});

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    clientId={config.clientId}
    redirectUri={window.location.origin}
    audience={`https://${config.domain}/api/v2/`}
    scope="read:current_user update:current_user_metadata"
    useRefreshTokens={true}
    >
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root')
);
