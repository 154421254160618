import TipPopover from './tip-popover'

const FaceTipPopover = (props) => {
  return (<TipPopover {...props}>
    <div>
      <h5>Face</h5>
      <p>Face is the front of a channel letter sign. Usually made out of acrylic for light to pass through, vinyl can be applied for different colors.</p>
      <img src="https://user-images.githubusercontent.com/99938590/155649252-3d2b71c8-6f82-488a-acfd-0e460df66127.png"></img>
    </div>
  </TipPopover>)
}

export default FaceTipPopover;