import React from 'react';
import { observer } from 'mobx-react-lite';
import { Navbar, Button, Alignment } from '@blueprintjs/core';

import { unstable_registerToolbarComponent } from 'polotno/config';
import LoginButton from './login-button';
import LogoutButton from './logout-button';
import ProfileViewButton from './show-profile-button';
import { useAuth0 } from "@auth0/auth0-react";
import NewElementButton from './new-element-button';
import { getConfig } from "../config";
import { isFinite, isEmpty, merge, cloneDeep } from 'lodash';
import { InputGroup, FormGroup, Tab, Tabs, MenuItem, NumericInput, ControlGroup, Intent } from '@blueprintjs/core';
import { generatePDF } from '../utils/pdf-functions';




const config = getConfig();

const AppToolbar = observer(({ store }) => {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  
  const pageHasElements = !isEmpty(store?.activePage?.children);
  const canSave = isFinite(store.width)

  const IS_DEV = config.env === 'development';

  const saveState = async () => {
    if (!isFinite(store.width)) return;

    const domain = config.domain;
    const json = store.toJSON();

    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scopes: "update:current_user",
    });

    const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

    const response = await fetch(userDetailsByIdUrl, {
      method: 'PATCH',
      body: JSON.stringify({user_metadata: { state: json }}),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json'
      },
    })
    console.log('saved', response?.status)
  }

  const openState = async () => {
    const response = await fetch('ss-templates/sign-template-components.json');
    let currentState = store.toJSON()
    const json = await response.json()
    let tempArr = []
    tempArr.push(...json);
    tempArr.push(...currentState.pages[0].children);
    let newPageState = cloneDeep(currentState.pages[0])
    delete newPageState.children
    newPageState.children = tempArr;
    currentState.pages = [newPageState];
    store.loadJSON(currentState, true);
  }

  return (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading>Vizorteam - Sign Studio</Navbar.Heading>
       
        {isAuthenticated && <Navbar.Divider />}
        <NewElementButton store={store} />

        {isAuthenticated && pageHasElements && <Navbar.Divider />}

        {pageHasElements && <Button className="bottom-margin"  icon="document" intent={Intent.SUCCESS} text='DETAILS SHEET' onClick={() => generatePDF(store, isAuthenticated)} />}

      </Navbar.Group>


      <Navbar.Group align={Alignment.RIGHT}>
        {IS_DEV && isAuthenticated && canSave && <>
        {<Button icon='cloud-download' onClick={openState} />}
        {<Navbar.Divider />}
        {<Button icon='export' onClick={() => console.log(store.toJSON())} />}
        {<Navbar.Divider />}
        </>}
        {isAuthenticated && canSave && <Button icon='floppy-disk' onClick={saveState} />}
        {isAuthenticated && canSave &&<Navbar.Divider />}
        <ProfileViewButton
          store={store}
          buttonText="Profile"
          includeFooter={false}/>

        <LoginButton></LoginButton>
        {isAuthenticated && <Navbar.Divider />}
        <LogoutButton></LogoutButton>
      </Navbar.Group>
    </Navbar>
  );
});

unstable_registerToolbarComponent('login', AppToolbar);

export default AppToolbar;