import React from 'react';
import { observer } from 'mobx-react-lite';
import { SidePanel } from 'polotno/side-panel';
import { InputGroup } from '@blueprintjs/core';

// import default tab component
import { SectionTab } from 'polotno/side-panel';
// import our own icon
import FaShapes from '@meronex/icons/fa/FaShapes';

import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { SimpleSigns } from '../../../constants/templates';

import { cloneDeep } from 'lodash';

export const PhotosPanel = observer(({ store }) => {
  
  // Unselect elements so when an element is clicked again edit panel show up
  store.selectElements([]);

  const [images, setImages] = React.useState([]);

  const appendState = async (path) => {
    const response = await fetch(path);
    let currentState = store.toJSON()
    const json = await response.json()
    let newComponents = json.map(element => {element.id = crypto.randomUUID(); return element;})
    let pageElements = []
    pageElements.push(...newComponents);
    pageElements.push(...currentState.pages[0].children);
    let newPageState = cloneDeep(currentState.pages[0])
    delete newPageState.children
    newPageState.children = pageElements;
    currentState.pages = [newPageState];
    store.loadJSON(currentState, true);
  }

  async function loadImages() {
    // here we should implement your own API requests
    setImages([]);

    // wait to emulate network request
    await new Promise((resolve) => setTimeout(resolve, 50));

    // for demo images are hard coded
    // in real app here will be something like JSON structure
    setImages(SimpleSigns);
  }

  React.useEffect(() => {
    loadImages();
  }, []);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <InputGroup
        leftIcon="search"
        placeholder="Search..."
        onChange={(e) => {
          loadImages();
        }}
        style={{
          marginBottom: '20px',
        }}
      />
      <p>Sign templates: </p>
      {/* you can create yur own custom component here */}
      {/* but we will use built-in grid component */}
      <ImagesGrid
        images={images}
        getPreview={(image) => image.url}
        onSelect={async (image, pos, element) => {
          // image - an item from your array
          // pos - relative mouse position on drop. undefined if user just clicked on image
          // element - model from your store if images was dropped on an element.
          //    Can be useful if you want to change some props on existing element instead of creating a new one
          appendState(image.path)
        }}
        rowsNumber={2}
        isLoading={!images.length}
        loadMore={false}
      />
    </div>
  );
});

// define the new custom section
export const TemplatesSection = {
  name: 'templates',
  Tab: (props) => (
    <SectionTab name="Templates" {...props}>
      <FaShapes icon="new-text-box" />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: PhotosPanel
};