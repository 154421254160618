export const THREE_M_COLORS = [
{color: '#ffc807',	label: 'Yellow', },
{color: '#fbac18',	label: 'Marigold', },
{color: '#faa619',	label: 'Sunflower', },
// {color: '#fbac18',	label: 'Golden Yellow', }, // Duplicate
{color: '#fdbb11',	label: 'Autumn Yellow', },
{color: '#febe1b',	label: 'Yellow Rose', },
{color: '#f9a331',	label: 'Poppy Orange', },
{color: '#f58430',	label: 'Kumquat Orange', },
{color: '#f58220',	label: 'Tangerine', },
{color: '#f15c3c',	label: 'Orange', },
{color: '#f05a3f',	label: 'Burnt Orange', },
{color: '#ee3a37',	label: 'Light Tomato Red', },
{color: '#ee3237',	label: 'Poppy Red', },
{color: '#ee3846',	label: 'Scarlet', },
{color: '#e83337',	label: 'Fire Engine Red', },
{color: '#e43339',	label: 'Red', },
{color: '#d5373b',	label: 'Dark Red', },
{color: '#d43739',	label: 'Cardinal Red', },
{color: '#dd3644',	label: 'Regal Red', },
{color: '#96313b',	label: 'Burgundy', },
{color: '#973b51',	label: 'Berry Burgundy', },
{color: '#f27497',	label: 'Rose Mauve', },
{color: '#c64483',	label: 'Intense Magenta', },
{color: '#e94892',	label: 'Fuchsia', },
{color: '#d2245d',	label: 'Raspberry', },
{color: '#e51d67',	label: 'Electric Pink', },
{color: '#ee395a',	label: 'Vivid Rose', },
{color: '#e272ac',	label: 'Pink', },
{color: '#70456b',	label: 'Plum Purple', },
{color: '#603a72',	label: 'Bright Violet', },
{color: '#3e4584',	label: 'Indigo', },
{color: '#40337f',	label: 'Rotal Blue', },
{color: '#11499e',	label: 'Electric Blue', },
{color: '#254273',	label: 'Blue', },
{color: '#06539e',	label: 'Patriot Blue', },
{color: '#0076bd',	label: 'Deep Sea Blue', },
{color: '#63a7d9',	label: 'Azure', },
{color: '#0083ba',	label: 'Lught European Blue', },
{color: '#0089bb',	label: 'Olympic Blue', },
{color: '#007dc3',	label: 'Process Blue', },
{color: '#0072b0',	label: 'Intense Blue', },
{color: '#0062a0',	label: 'Bright Blue', },
{color: '#005899',	label: 'Bristol Blue', },
{color: '#0061a8',	label: 'Sapphire', },
{color: '#00537d',	label: 'Marine Blue', },
{color: '#204981',	label: 'Twilight Blue', },
{color: '#334471',	label: 'Infinity Blue', },
{color: '#2e426e',	label: 'European Blue', },
{color: '#b1c8e8',	label: 'Evening Blue', },
{color: '#005766',	label: 'Glacial Green', },
{color: '#006f81',	label: 'Blue Coral', },
{color: '#00878d',	label: 'Teal Green', },
{color: '#00978d',	label: 'Turquoise', },
{color: '#00987e',	label: 'Blue Lagoon', },
{color: '#005e59',	label: 'Jade Green', },
{color: '#006245',	label: 'Dark Emerald Green', },
{color: '#007143',	label: 'Green', },
{color: '#005c3c',	label: 'Holly Green', },
{color: '#00aea9',	label: 'Bright Jade Green', },
{color: '#008251',	label: 'Kentucky Blue Grass', },
{color: '#008355',	label: 'Forest', },
{color: '#00a56f',	label: 'Light Kelly Green', },
{color: '#008d4a',	label: 'Vivid Green', },
{color: '#00af4c',	label: 'Lime Green', },
{color: '#0db14b',	label: 'Brilliant Green', },
{color: '#ead916',	label: 'Light Lemon Yellow', },
{color: '#fdcfad',	label: 'Ivory', },
{color: '#d5bfa6',	label: 'Light beige', },
{color: '#fed5a7',	label: 'Warm Beige', },
{color: '#8a463f',	label: 'Rust Brown', },
{color: '#5d4640',	label: 'Dark Brown', },
{color: '#84332e',	label: 'Liught Rust Brown', },
{color: '#584f4b',	label: 'Duranodic', },
{color: '#bb6228',	label: 'Bronze', },
{color: '#bbbbbc',	label: 'Silver', },
{color: '#cb9952',	label: 'Gold Nugget', },
{color: '#b89775',	label: 'Gold Metallic', },
{color: '#99918d',	label: 'Shadow Gray', },
{color: '#8b9491',	label: 'Silver Gray', },
{color: '#63767f',	label: 'Slate Gray', },
{color: '#2d2926',	label: 'Black', },
];

export const TRIM_CAP_COLORS = [
  { color: '#000000', label: 'Black' },
  { color: '#ffffff', label: 'White' },
  { color: '#c91920', label: 'True Red' },
  { color: '#00693e', label: 'Greed (Solid)' },
  { color: '#0061aa', label: 'Intense Blue' },
  { color: '#23316c', label: 'Blue (Solid)' },
  { color: '#635632', label: 'Bronze' },
  { color: '#b3b268', label: 'Gold' },
  { color: '#d2d3d5', label: 'Chrome' },
];

export const RETURN_COLORS = [
  
  { color: '#051821', label: 'Gloss Black' },
  { color: '#f7f8f8', label: 'Ultra White' },
  { color: '#c40e3c', label: 'Hunter Red' },
  { color: '#0060a0', label: 'Chevron Blue' },
  { color: '#00447b', label: 'Heron Blue' },
  { color: '#46441f', label: 'Dark Bronze' },
  
];

export const ALL_COLORS = [
  ...THREE_M_COLORS,
  ...TRIM_CAP_COLORS,
  ...RETURN_COLORS
]