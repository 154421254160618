import { Button, Intent } from '@blueprintjs/core'
import { Classes, Popover2 } from '@blueprintjs/popover2'
import { findLastIndex } from 'lodash';

const TipPopover = ({children, ...props}) => {
  const showHelp = !props.hideHelp;
  return (<Popover2
                interactionKind="hover"
                popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
                placement="bottom"
                content={children}
                renderTarget={({ isOpen, ref, ...targetProps }) => (
                    <div style={{
                        display:  'flex',
                      }}>
                    <p style={{
                        background: `${props.color}`,
                        color: 'white',
                        display:  'flex',
                        borderRadius: 15,
                        paddingLeft: '5px',
                        paddingRight: '5px'
                      }}>{props.title}</p>
                    {showHelp && <Button style={{paddingTop: '0px' }} intent={Intent.SUCCESS} {...targetProps} elementRef={ref} intent="primary" icon="help" minimal={true} />}
                    </div>
                )}
            />)
}

export default TipPopover;