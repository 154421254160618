// Logic for auto resize text selection box
// Source: https://stackoverflow.com/a/21015393
// Assumes font size is in px always.
export const getTextWidthFor = (textElement, text) => {
  const canvas = window.document.getElementsByTagName('canvas')[0]; // There's only one canvas

  const ctx = canvas?.getContext('2d')
  if (!ctx) return
  const fontDescriptor = `${textElement?.fontStyle} ${textElement?.fontSize}px ${textElement?.fontFamily.toLowerCase()}`
  ctx.font = fontDescriptor
  // ctx.font = fontSizePx + 'px ' + fontName;
  // ctx.textAlign = 'left';
  // ctx.fillStyle = 'blue';
  // ctx.textBaseline = 'baseline';

  const metrics = ctx.measureText(text ? text : textElement?.text || textElement?.placeholder);
  console.log(metrics);
  textElement.set({ width: metrics.width, height: metrics.actualBoundingBoxAscent +  metrics.actualBoundingBoxDescent})
  return metrics.width;
}