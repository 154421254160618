import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { observer } from 'mobx-react-lite';
import { Button, Intent, Colors } from '@blueprintjs/core';
import { createText } from './../utils/ziyad-functions'
import { bind } from 'lodash';



const NewElementButton = observer(({ store }) => {
  const { isAuthenticated } = useAuth0();
  const handleCreateText = bind(createText, {}, store, null)

  return (isAuthenticated &&
    <Button intent={Intent.SUCCESS} icon="new-text-box" text='CREATE TEXT' onClick={handleCreateText} />
  )
});

export default NewElementButton;

          