import React from 'react';
import { Text } from 'react-konva';
import { observer } from 'mobx-react-lite';

import { unstable_registerShapeComponent } from 'polotno/config';
import { unstable_registerShapeModel } from 'polotno/config';

// polotno util function
import { useSnap } from 'polotno/canvas/use-snap';

// define our model
// we need to provide all default values
unstable_registerShapeModel({
  type: 'text-plus',
  x: 0,
  y: 0,
  rotation: 0,
  locked: false,
  blurEnabled: false,
  blurRadius: 10,
  brightnessEnabled: false,
  brightness: 0,
  shadowEnabled: false,
  shadowColor: 'white',
  shadowOffsetX: 1,
  shadowOffsetY: 1,
  shadowBlur: 10,
  text: 'Hey, polotno',
  fontSize: 14,
  fontFamily: 'Roboto',
  fontStyle: 'normal', // can be normal or italic
  fontWeight: 'normal', // can be normal or bold or some other CSS variations
  textDecoration: '',
  fill: 'black',
  align: 'center',
  verticalAlign: 'top',
  strokeWidth: 0,
  stroke: 'black',
  lineHeight: 1,


  width: 100,
  height: 100,

  // can user select element?
  // if false, element will be "invisible" for user clicks
  selectable: true,
  // use for absolute positing of element
  alwaysOnTop: false,
  // also we can hide some elements from the export
  showInExport: true,


  // placeholder is working similar to input placeholder
  // it will be rendered if no text is defined
  // and we will use it in input element too
  // useful for template canvas, where users will need to replace text elements
  placeholder: '',
});


// This is a custom implementation of Text element from Konvajs that implements shadows for Text.
// Current polotno sdk doesn't support shodows for Text elements.
export const TextPlusElement = observer(({ onClick, element, store }) => {
  const ref = React.useRef();

  // useSnap - is utility hook that automatically enables snapping
  const { onDragMove, onDragEnd } = useSnap(ref);

  const handleChange = (e) => {
    const node = e.currentTarget;
    // const scaleX = node.scaleX();
    // const scaleY = node.scaleX();
    // Konva.Transformer is changing scale by default
    // we don't need that, so we reset it back to 1.
    node.scaleX(1);
    node.scaleY(1);
    element.set({
      x: node.x(),
      y: node.y(),
      // rotation: e.target.rotation(),
      // width: element.width * scaleX,
      // height: element.height * scaleY,
    });
  };

  // VERY IMPORTANT note!
  // element.x and element.y - must define top-left corner of the shape
  // so all position attributes are consistent across all elements
  return (
    <Text
      ref={ref}
      name="element"
      id={element.id}
      text={element.text}
      placeholder={element.placeholder}
      x={element.x}
      y={element.y}
      width={element.width}
      height={element.height}
      fill={element.fill}
      stroke={element.stroke}
      strokeWidth={element.strokeWidth}
      fontSize={element.fontSize}
      fontFamily={element.fontFamily}
      fontStyle={element.fontStyle}
      fontWeight={element.fontWeight}
      textDecoration={element.textDecoration}
      lineHeight={element.lineHeight}
      shadowEnabled={element.shadowEnabled}
      shadowColor={element.shadowColor}
      shadowOffsetX={element.shadowOffsetX}
      shadowOffsetY={element.shadowOffsetY}
      // align={element.align}
      verticalAlign={element.verticalAlign}
      padding={0}
      locked={element.locked}
      selectable={element.selectable}
      rotation={element.rotation}
      draggable={!element.locked}
      onDragStart={() => {
        const isSelected = store.selectedElements.find((e) => e === element);
        if (!isSelected) {
          store.selectElements([element.id]);
        }
      }}
      onTap={() => {
        onClick();
      }}

      onDragMove={(e) => {
        onDragMove(e);
      }}
      onDragEnd={(e) => {
        onDragEnd(e);
        handleChange(e);
      }}
      onTransform={(e) => {
        handleChange(e)
      }}
    />
  );
});

// now we can register canvas component
unstable_registerShapeComponent('text-plus', TextPlusElement);
