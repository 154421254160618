import React from "react";
import { Button, Dialog, Classes } from '@blueprintjs/core';
import { Tooltip2 } from "@blueprintjs/popover2";
import Profile from "./profile";
import { useAuth0 } from "@auth0/auth0-react";


function DialogBody() {
  return (
      <div className={Classes.DIALOG_BODY}>
        <Profile></Profile>
      </div>
  );
}

function DialogFooter(props)  {
  return (
      <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Tooltip2 content="This button is hooked up to close the dialog.">
                  <Button onClick={props.handleClose}>Close</Button>
              </Tooltip2>
              <Button fill={true} text="Ok" />
          </div>
      </div>
  );
}

const ProfileViewButton = ({
  buttonText,
  includeFooter,
  ...props
}) => {
const { isAuthenticated } = useAuth0();

  const [isOpen, setIsOpen] = React.useState(false);
  const handleButtonClick = React.useCallback(() => setIsOpen(!isOpen), []);
  const handleClose = React.useCallback(() => setIsOpen(false), []);
  return (isAuthenticated &&
      <>
          <Button onClick={handleButtonClick} text={buttonText} />
          <Dialog {...props} isOpen={isOpen} onClose={handleClose}>
              <DialogBody />
              {includeFooter ? (
                  <DialogFooter handleClose={handleClose} />
              ) : (
                  <div style={{ margin: "0 20px" }}>
                      <Button  onClick={handleClose} fill={true} text="Ok" />
                  </div>
              )}
          </Dialog>
      </>
  );
};

export default ProfileViewButton;