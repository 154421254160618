import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";

const profileContainerStyle = {
  "display": "flex",
  "flex-direction": "column",
  "flex-wrap": "wrap",
  "align-content": "center",
  "justify-content": "center",
  "align-items": "center"
}

const profileImageStyle = {
  display: "flex"
}

// Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getConfig();


const Profile = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = config.domain;
  
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        });
  
        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
  
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const { user_metadata } = await metadataResponse.json();
  
        setUserMetadata(user_metadata);
      } catch (e) {
        console.log(e.message);
      }
    };
  
    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
    <div style={profileContainerStyle}>
      {/* <img src={user.picture} alt={user.name} /> */}
      <svg style={profileImageStyle} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="170" height="200">
        <defs>
          <filter id="filter">
            <feGaussianBlur stdDeviation="5" />
          </filter>
          <mask id="mask">
            <ellipse cx="50%" cy="50%" rx="25%" ry="25%" fill="white" filter="url(#filter)"></ellipse>
          </mask>
        </defs>

        <image href={user.picture} width="170" height="200" mask="url(#mask)"></image>
      </svg>
      <h2>{user.name}</h2>
      <p>{user.email}</p>
      <h3>User Configuration:</h3>
        {userMetadata ? (
          <pre>Pricing Plan: {JSON.stringify(userMetadata.pricing, null, 2)}</pre>
        ) : (
          "No user metadata defined"
        )}
    </div>
      
    )
  );
};

export default Profile;