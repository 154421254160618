import TipPopover from './tip-popover'

const TrimCapTipPopover = (props) => {
  return (<TipPopover {...props}>
    <div>
      <h5>Trimcap</h5>
      <p>Trimcap is a plastic molding that surrounds the acrylic channel letter face.</p>
      <img src="https://user-images.githubusercontent.com/99938590/155649250-61609a25-3c33-4faf-a1d7-b87537d2722f.png"></img>
    </div>
  </TipPopover>)
}

export default TrimCapTipPopover;