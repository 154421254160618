import React from 'react';
import { CirclePicker } from 'react-color';
import { map, find, defaultTo, cloneDeep } from  'lodash';
import { Tab, Tabs } from '@blueprintjs/core';
import { THREE_M_COLORS, ALL_COLORS } from '../constants/colors';
import { observer } from 'mobx-react-lite';
import { TwitterPicker } from 'react-color';
import TipPopover from './tips/tip-popover';


const SvgColorSelector = observer(({ store, element }) => {

  if (!element) return
  const colors = element.colors;
  const colorsReplace = element.colorsReplace;

  let tabs = []
  colors.forEach((originalColor,index) => {
    let color = colorsReplace.get(originalColor) ? colorsReplace.get(originalColor) : originalColor;

    const handleChangeComplete = (color) => {
      element.replaceColor(colors[index], color.hex)
      color = colorsReplace.get(originalColor) ? colorsReplace.get(originalColor) : originalColor;
    
    };
  
    const handleColorHover = (color, event) => {
      event.target.title = find(ALL_COLORS,  {color: color.hex })?.label;
    }

    let tabId = `color-${index}-tab`

    tabs.push(
      <Tab id={tabId} children={<TipPopover title={`Color ${index + 1}`} color={color} hideHelp />}   panel={<TwitterPicker className='twipic'
      color={ color }
      colors={map(THREE_M_COLORS, 'color')}
      triangle= {'hide'}
      width={320}
      onSwatchHover = { handleColorHover}
      onChangeComplete={ handleChangeComplete }
    />} />)
    });


    const handleTabChange = (svgColorTabId) => {
      let custom = cloneDeep(store.activePage?.custom);
      custom[element.id] = {svgColorTabId};
      store.activePage.set({ custom })
    }

    const DEFAULT_TAB_ID = `color-0-tab`;
    let selectedTabId = defaultTo(store.activePage?.custom[element.id]?.svgColorTabId, DEFAULT_TAB_ID)

  return (
    <Tabs className="centered" id="TabsExample" onChange={handleTabChange} selectedTabId={selectedTabId}>
    {tabs}
    <Tabs.Expander />
</Tabs>)
});

export default SvgColorSelector;