export const SimpleSigns = [
  {
    name: 'Sign 1',
    path: 'ss-templates/sign-template-components-1.json',
    url: './images/template-1.png',
  },
  {
    name: 'Sign 2',
    path: 'ss-templates/sign-template-components-2.json',
    url: './images/template-2.png',
  },
  {
    name: 'Sign 3',
    path: 'ss-templates/sign-template-components-3.json',
    url: './images/template-3.png',
  },
  {
    name: 'Sign 4',
    path: 'ss-templates/sign-template-components-4.json',
    url: './images/template-4.png',
  }
]