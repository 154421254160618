import { observer } from 'mobx-react-lite';
import { toNumber, trimEnd, isNumber, assign } from 'lodash';
import { EXLUDED_ELEMENTS_IDS } from '../utils/ziyad-functions'

let x = 0
const incrementCounter = () => {
  x++
}

const divStyle = {
    position: 'absolute',
    bottom: '4px',
    right: '5px',
    width: 'auto'
  };

export const SomeAppComponent = observer(({ store }) => {
  const verticalRulerElement = store.getElementById('verticalRulerText');
  const shortestSide = toNumber(trimEnd(verticalRulerElement?.text, '"'))
  let charCount = store?.activePage?.children.filter(x => !EXLUDED_ELEMENTS_IDS.includes(x.id));
  charCount = charCount ? charCount[0]?.text?.length : 0
  const pricePerInch = 1.5;
  const price = (isNumber(charCount) ? Math.trunc((shortestSide * pricePerInch) * toNumber(charCount), 2) : 0)
  if (store?.activePage?.custom?.price !== price)
    store?.activePage?.set({ custom: { ...store?.activePage?.custom, price}})
  // console.log(shortestSide, charCount)

  return (
    <div style={divStyle} onClick={incrementCounter}>
      <div className="bp3-card bp3-interactive">
        Your total is ${price}
      </div>
    </div>
  );
});