import { InputGroup, FormGroup, Button, Tab, Tabs, MenuItem, NumericInput, ControlGroup, Intent, Tag, Icon } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { CirclePicker } from 'react-color';
import { isEmpty, map, find } from 'lodash';
import { Select } from "@blueprintjs/select";
import { generatePDF } from '../../../utils/pdf-functions';
import { FONTS } from '../../../constants/fonts'
import { FixedSizeList as List } from 'react-window';
import { THREE_M_COLORS, RETURN_COLORS, TRIM_CAP_COLORS, ALL_COLORS } from '../../../constants/colors';
import FaceTipPopover from '../../tips/face-tip-popover'
import ReturnTipPopover from '../../tips/return-tip-popover'
import TrimCapTipPopover from '../../tips/trimcap-tip-popover'
import SvgColorSelector from '../../svg-color-selector'
import { getTextWidthFor } from '../../../utils/canvas';
import { SectionTab } from 'polotno/side-panel';
import FaShapes from '@meronex/icons/fa/FaShapes';


export const EditingSection = {
  name: 'edit-section',
  // we don't need "Tab" property, because it will be hidden from the list
  visibleInList: false,
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }) => {
    let selectedTextComponent = null;
    const activePage = store.activePage;
    const selectedElement = store.selectedElements[0];

    const filterFont = (query, font) => {
      return font.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    };
    const renderFont = (font, { handleClick, modifiers }) => {
      if (!modifiers.matchesPredicate) {
          return null;
      }
      return (
          <MenuItem
              active={modifiers.active}
              key={font}
              label={font}
              onClick={handleClick}
              text={font}
          />
      );
    };

    const pageHasElements = store?.activePage?.children;

    const isTextElement = store?.selectedElements[0]?.custom?.textType === 'sign'
    if (isTextElement) selectedTextComponent = selectedElement;

    const isSVGElement = store?.selectedElements[0]?.type === 'svg'

    const handleChangeComplete = textElement => (color) => {
      switch (activePage?.custom?.tabId) {
        case 'face-tab':
          textElement.set({custom: { ...textElement.custom, faceColor: color.hex }})
          break;
        case 'trimcap-tab':
          textElement.set({custom: { ...textElement.custom, trimCap: color.hex }})
          break;
        case 'return-tab':
          textElement.set({custom: { ...textElement.custom, returnColor: color.hex }})
          break;
        default:
          textElement.set({custom: { ...textElement.custom, faceColor: color.hex }})
          break;
      }

      selectedElement.set({ strokeWidth: 2, fill: textElement?.custom?.faceColor, stroke: textElement?.custom?.trimCap, shadowColor: textElement?.custom?.returnColor})
    };

    const handleColorHover = (color, event) => {
      event.target.title = find(ALL_COLORS,  {color: color.hex })?.label;
    }

    const FaceColorPicker = observer(({ isTextElement, selectedTextComponent, face }) => {
      if (isTextElement)
        return <CirclePicker
        color={ face }
        colors={map(THREE_M_COLORS, 'color')}
        circleSize={20}
        width={320}
        onSwatchHover = { handleColorHover }
        onChangeComplete={ handleChangeComplete(selectedTextComponent) }
      />;
      return <div></div>
    })

    const TrimCapColorPicker = observer(({ isTextElement, selectedTextComponent, trimCap }) => {
      if (isTextElement)
        return <CirclePicker
        color={ trimCap }
        colors={map(TRIM_CAP_COLORS, 'color')}
        circleSize={20}
        width={320}
        onSwatchHover = { handleColorHover }
        onChangeComplete={ handleChangeComplete(selectedTextComponent) }
      />;
      return <div></div>
    })

    const ReturnColorPicker = observer(({ isTextElement, selectedTextComponent, returnColor }) => {
      if (isTextElement)
        return <CirclePicker
        color={ returnColor }
        colors={map(RETURN_COLORS, 'color')}
        circleSize={20}
        width={320}
        onSwatchHover = { handleColorHover }
        onChangeComplete={ handleChangeComplete(selectedTextComponent) }
      />;
      return <div></div>
    })

    const handleTextChange = textElement => (event) => {
      const text = event.target?.value;
      const width = getTextWidthFor(textElement, text)
      textElement?.set({ text });
    }

    const TextCapture = observer(({ isTextElement, selectedTextComponent }) => {
      if (isTextElement)
        return <FormGroup
              // helperText="Enter text here..."
              label="Text"
              labelFor="text-input"
              labelInfo="(required)"
          >
              <InputGroup id="text-input" placeholder="Template" onChange={handleTextChange(selectedTextComponent)} value={selectedTextComponent?.text} />
          </FormGroup>;
      return <div></div>
    })

    const handleTabChange = (tabId) => {
      activePage.set({ custom: { ...activePage?.custom, tabId}})
    }

    const ShowColors = observer(({ isTextElement, selectedTextComponent }) => {
      if (isTextElement) {
        return (
          <Tabs className="centered colors-pallate" id="TabsExample" onChange={handleTabChange} selectedTabId={activePage?.custom?.tabId}>
          <Tab id="face-tab" children={<FaceTipPopover title= "Face" color={selectedTextComponent?.custom?.faceColor} />} panel={<FaceColorPicker isTextElement={isTextElement} selectedTextComponent={selectedTextComponent} face={selectedTextComponent?.custom?.faceColor}/>} />
          <Tab id="trimcap-tab" children={<TrimCapTipPopover title="TrimCap" color={selectedTextComponent?.custom?.trimCap} />} panel={<TrimCapColorPicker isTextElement={isTextElement} selectedTextComponent={selectedTextComponent} trimCap={selectedTextComponent?.custom?.trimCap} />}  />
          <Tab id="return-tab" children={<ReturnTipPopover title="Return" color={selectedTextComponent?.custom?.returnColor} />} panel={<ReturnColorPicker isTextElement={isTextElement} selectedTextComponent={selectedTextComponent} returnColor={selectedTextComponent?.custom?.returnColor} />}  />
          <Tabs.Expander />
      </Tabs>)
      
      }
      return <div></div>;
    })

    const ShowOrderSheet = observer(({ pageHasElements }) => {
      if (isEmpty(pageHasElements)) return <div></div>;
      
      return (
        <div></div>        )
    });

      return (
        <div>
          {isSVGElement && <SvgColorSelector store={store} element={selectedElement} />}
          <ShowOrderSheet pageHasElements={pageHasElements} />
          <TextCapture isTextElement={isTextElement} selectedTextComponent={selectedTextComponent} />
          <ControlGroup fill className="bottom-margin">
            {isTextElement ? <Select
                items={FONTS}
                // itemListRenderer={Example}
                itemPredicate={filterFont}
                itemRenderer={renderFont}
                resetOnClose={false}
                activeItem={selectedTextComponent?.fontFamily}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={(font) => {
                  selectedTextComponent?.set({ fontFamily: font })
                  getTextWidthFor(selectedTextComponent)
                }}
            >
                {/* children become the popover target; render value here */}
                <Button fill large text={selectedTextComponent?.fontFamily} rightIcon="double-caret-vertical" />
            </Select> : null}
            { isTextElement ?  <NumericInput large 
              onValueChange={(fontSize) => {
                getTextWidthFor(selectedTextComponent)
                selectedTextComponent.set({ fontSize: fontSize * 10 });
              }}
              value={selectedTextComponent.fontSize / 10}
              style={{ width: '50px', marginLeft: '10px' }}
              min={6}
              max={48}
            /> : null}
          </ControlGroup>
          <ShowColors bottom isTextElement={isTextElement} selectedTextComponent={selectedTextComponent} />
        </div>
      );
    }),
};

