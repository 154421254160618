import { Colors } from '@blueprintjs/core';
import { FONTS } from '../constants/fonts'
import { getTextWidthFor } from './canvas'

export const EXLUDED_ELEMENTS_IDS = ['leftMostValue', 'rightMostValue', 'bottomMostValue', 'topMostValue', 'horizontalRuler', 'verticalRuler', 'horizontalRulerText', 'verticalRulerText']
const { isFinite, isEmpty, isNil } = require('lodash');
export const getElementsIDs = (store) => store.activePage.children.filter(x => !EXLUDED_ELEMENTS_IDS.includes(x.id)).map(x => x.id) 
export const updatePoints = (store, displayNumbers = false) => {
  if (!store.activePage) return {}

  // TODO: this is not accurate when a rotation is applied, so it's considered a BUG
  const left = displayNumbers ? updateLeftMostPoint(store) : getLeftMostValue(store);
  const right = displayNumbers ? updateRightMostPoint(store) : getRightMostValue(store);
  const bottom = displayNumbers ? updateBottomMostPoint(store) : getBottomMostValue (store);
  const top = displayNumbers ? updateTopMostPoint(store) : getTopMostValue(store);
  return {
    left, right, bottom, top
  }
}

export const updateLeftMostPoint = (store) => {
  const ID = 'leftMostValue';
  const element = getElementOrCreate(store, ID)
  const leftMostValue = getLeftMostValue(store);
  element.set({ text: ` LeftMostPoint: ${leftMostValue.toString()}` });
  return leftMostValue;
}

export const updateRightMostPoint = (store) => {
  const ID = 'rightMostValue';
  const element = getElementOrCreate(store, ID)
  const rightMostValue = getRightMostValue(store);
  element.set({ text: ` RightMostPoint: ${rightMostValue.toString()}` });
  return rightMostValue;
}

export const updateBottomMostPoint = (store) => {
  const ID = 'bottomMostValue';
  const element = getElementOrCreate(store, ID)
  const bottomMostValue = getBottomMostValue(store);
  element.set({ text: ` BottomMostPoint: ${bottomMostValue.toString()}` });
  return bottomMostValue;
}

export const updateTopMostPoint = (store) => {
  const ID = 'topMostValue';
  const element = getElementOrCreate(store, ID)
  const topMostValue = getTopMostValue(store);
  element.set({ text: ` TopMostPoint: ${topMostValue.toString()}` });
  return topMostValue
}

const getLeftMostValue = (store) => Math.trunc(Math.min(...store.activePage.children.filter(x => !EXLUDED_ELEMENTS_IDS.includes(x.id)).map(x => x.x)))
const getRightMostValue = (store) => Math.trunc(Math.max(...store.activePage.children.filter(x => !EXLUDED_ELEMENTS_IDS.includes(x.id)).map(x => x.x + x.width)))
const getBottomMostValue = (store) => Math.trunc(Math.max(...store.activePage.children.filter(x => !EXLUDED_ELEMENTS_IDS.includes(x.id)).map(x => x.y + x.height)))
const getTopMostValue = (store) => Math.trunc(Math.min(...store.activePage.children.filter(x => !EXLUDED_ELEMENTS_IDS.includes(x.id)).map(x => x.y)))

const getElementOrCreate = (store, id) => {
  let xPos = 0, yPos = 0;
  switch (id) {
    case 'leftMostValue':
      xPos = 50;
      yPos = 50;
      break;
    case 'rightMostValue':
      xPos = 250;
      yPos = 50;
      break;
    case 'bottomMostValue':
      xPos = 50;
      yPos = 75;
      break;
    case 'topMostValue':
      xPos = 250;
      yPos = 75;
      break;
    default:
  }
  let element = store.getElementById(id);
  if (!element && store.activePage){
    element = store.activePage.addElement({
      id: id,
      type: 'text',
      x: xPos,
      y: yPos,
      fill: 'black',
      text: 'hello',
    });
  }
  if (element) element.set({ width: 150 })
  return element
}

export const addOrUpdateHorizontalRuler = (store, leftPoint, rightPoint) => {
  const ID = 'horizontalRuler';
  let element = store.getElementById(ID);
  let horzontalSizeText = store.getElementById(`${ID}Text`);

  if (!isFinite(leftPoint) || !isFinite(rightPoint)) {
    if (element) store.deleteElements([element.id])
    if (horzontalSizeText) store.deleteElements([horzontalSizeText.id])
    return
  }

  if (!element && store.activePage)
    element = store.activePage.addElement({
      id: 'horizontalRuler',
      type: 'svg',
      src: 'line.svg',
      maskSrc: '', // should we draw mask image over svg element?
      keepRatio: false, // can we change aspect ration of svg?
      x: 0,
      y: 25,
      rotation: 0,
      locked: true,
      blurEnabled: false,
      blurRadius: 10,
      brightnessEnabled: false,
      brightness: 0,
      shadowEnabled: false,
      shadowBlur: 10,
      width: 5,
      height: 40,
      flipX: false,
      flipY: false,
      // can user select element?
      // if false, element will be "invisible" for user clicks
      selectable: false,
      // use for absolute positing of element
      alwaysOnTop: false,
      // also we can hide some elements from the export
      showInExport: true,
    });

  if (!horzontalSizeText && store.activePage)
    horzontalSizeText = store.activePage.addElement({
      id: `${ID}Text`,
      type: 'text',
      x: 0,
      y: 0,
      fill: 'black',
      text: '',
      selectable: false,
      locked: true,
      showInExport: false,
    });

  if (element)
    element.set({
      x: leftPoint,
      width: rightPoint - leftPoint,
    })

  if (horzontalSizeText){
    const textSizeInPix = horzontalSizeText.text.length * 10;
    horzontalSizeText.set({ width: textSizeInPix, text: `${((rightPoint - leftPoint)/10).toString()}"`, x: leftPoint + ((rightPoint - leftPoint) / 2) - textSizeInPix/2, y: 24 })
  }
}

export const addOrUpdateVerticalRuler = (store, topPoint, bottomPoint) => {
  const ID = 'verticalRuler';
  let element = store.getElementById(ID)
  let verticalSizeText = store.getElementById(`${ID}Text`)

  if (!isFinite(topPoint) || !isFinite(bottomPoint)) {
    if (element) store.deleteElements([element.id])
    if (verticalSizeText) store.deleteElements([verticalSizeText.id])
    
    return
  }
  if (!element)
    element = store.activePage.addElement({
      id: ID,
      type: 'svg',
      src: 'line.svg',
      maskSrc: '', // should we draw mask image over svg element?
      keepRatio: false, // can we change aspect ration of svg?
      x: 0,
      y: 25,
      rotation: 90,
      locked: true,
      blurEnabled: false,
      blurRadius: 10,
      brightnessEnabled: false,
      brightness: 0,
      shadowEnabled: false,
      shadowBlur: 10,
      width: 0,
      height: 40,
      flipX: false,
      flipY: false,
      // can user select element?
      // if false, element will be "invisible" for user clicks
      selectable: false,
      // use for absolute positing of element
      alwaysOnTop: false,
      // also we can hide some elements from the export
      showInExport: true,
    });

  if (!verticalSizeText)
    verticalSizeText = store.activePage.addElement({
      id: `${ID}Text`,
      type: 'text',
      x: 0,
      y: 0,
      rotation: 270,
      fill: 'black',
      text: '',
      selectable: false,
      locked: true,
      showInExport: true,
    });

  element.set({
    y: topPoint,
    x: 50,
    height: 40,
    width: bottomPoint - topPoint,
  });

  const textSizeInPix = verticalSizeText.text.length * 10;
  if (verticalSizeText)
    verticalSizeText.set({ width: textSizeInPix, text: `${((bottomPoint - topPoint)/10).toString()}"`, y: topPoint + ((bottomPoint - topPoint) / 2) + textSizeInPix/3, x: 12 })

}

// resize canvas when objects moved outside of canvas on right or bottom sides
export const resizeCanvas = (store, { left, right, bottom, top }) => {
  if (right <= store.width && bottom <= store.height && right / store.width > 0.1 && bottom / store.height > 0.1) return;

  store.setSize(right * 1.15, bottom * 1.35)
}

// Recenter objects on canvas when it goes out of view through top or left side
export const recenterObjects = (store, { left, right, bottom, top }) => {
  if (left <= 30) {
    store.selectedElements.forEach(element => {
      if (element.x > 30) return;
      element.set({x: element.x + Math.abs(left) + 30})
    });
  }

  if (top <= 45) {
    store.selectedElements.forEach(element => {
      if (element.y > 45) return
      element.set({y: element.y + Math.abs(top) + 45})
    });
  }

}

export const createText = (store, propsLambda = null) => {
  let ele = store?.pages[0]?.addElement({ type: 'text-plus', x: 100, y: 200, fill: '#000000', stroke: '#000000', placeholder: 'Template',
  fontSize: 140,
  shadowEnabled: true,
  shadowOffsetX: 3,
  shadowOffsetY: 3,
  shadowBlur: 0,
  width: 590, // Because placeholder is Template, if that changes, this value needs to change
  shadowColor: 'red',
  fontFamily: FONTS[0],
  fontStyle: 'normal', // can be normal or italic
  fontWeight: 'normal',
  lineHeight: 1,
  selectable: true,
  locked: false});

  if (isNil(propsLambda))
    propsLambda = (element) => ({ custom: {textType: 'sign', font: FONTS[0], faceColor: element.fill, trimCap: element.stroke, returnColor: element.fill, fontSize: element.fontSize }});

  const elementProps = propsLambda(ele);
  ele?.set(elementProps);
  ;

  return ele;
};



export const createSampleComponents = (store) => {

  if (store.pages.length === 0)
    store.addPage()

    const welcomeMessagePropsLambda = (element) => ({ text: 'Hey There', x: 200, width: 630, height: 135, fontSize: 140, fill: '#06539e', stroke: '#b3b268', strokeWidth: 2, shadowColor: '#0060a0', custom: { textType: 'sign', ...element?.custom, faceColor: '#06539e', trimCap: '#b3b268', returnColor: '#0060a0' }});
    const clickMePropsLambda = (element) => ({ text: 'Click me to edit', fontFamily: 'Calibri', x: 300, y: 400, width: 450, height: 60, fontSize: 70, fill: '#00af4c', stroke: '#b3b268', strokeWidth: 2, shadowColor: '#c40e3c', custom: { ...element?.custom, textType: 'sign', faceColor: '#00af4c', trimCap: '#b3b268', returnColor: '#c40e3c', fontSize: 70 }});

    const ele1 = createText(store, welcomeMessagePropsLambda)
    const ele2 = createText(store, clickMePropsLambda)

    getTextWidthFor(ele1);
    getTextWidthFor(ele2);


    store.activePage.addElement({
      id: 'sample',
      type: 'svg',
      src: 'svgs/sample.svg',
      maskSrc: '', // should we draw mask image over svg element?
      keepRatio: false, // can we change aspect ration of svg?
      x: 50,
      y: 200,
      width: 150,
      height: 150,
      rotation: 0,
      locked: false,
      blurEnabled: false,
      blurRadius: 10,
      brightnessEnabled: false,
      brightness: 0,
      shadowEnabled: false,
      shadowBlur: 10,
      // width: 50,
      // height: 50,
      flipX: false,
      flipY: false,
      // can user select element?
      // if false, element will be "invisible" for user clicks
      selectable: true,
      // use for absolute positing of element
      alwaysOnTop: false,
      // also we can hide some elements from the export
      showInExport: true,
    });

}
