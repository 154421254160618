import React from "react";
import { observer } from 'mobx-react-lite';
import { SidePanelWrap } from 'polotno';
import { SidePanel, ElementsSection } from 'polotno/side-panel';
import { EditingSection, TemplatesSection } from './sections'


export const SignStudioSidePanel = observer(({ store }) => {

  const sections = [ElementsSection, TemplatesSection, EditingSection];

  return (
    <>
      <SidePanelWrap>
      <SidePanel store={store} sections={sections} defaultSection={EditingSection.name}/>
      </SidePanelWrap>
    </>
  );

});
