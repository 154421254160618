// import PDFDocument from 'pdfkit';
// import blobStream from 'blob-stream'
import pdfMake from 'pdfmake'
import pdfFonts from "pdfmake/build/vfs_fonts";
import SvgColorSelector from '../components/svg-color-selector';
import { render } from 'react-dom/cjs/react-dom.production.min';
import { getElementsIDs } from './ziyad-functions';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generatePDF = async (store, isAuthenticated) => {
  const img = await store.toDataURL({ mimeType: 'image/jpg', pageId: store.pages[0].id, pixelRatio: 2 })
  const elementsIDs = getElementsIDs(store);
  let items = [];
  const price = store.activePage.custom.price;
  console.log(store.activePage.custom);

  elementsIDs.forEach(id => {
    const element = store.getElementById(id);

    if (!element.custom) return 
    const { faceColor, trimCap, returnColor } = element.custom;
    const { fontFamily, fontSize} = element;
    items.push({ text: [
      'One Text with Face color ',
      { text: '     ', background: faceColor}, ' TrimCap of ',
      { text: '     ', background: trimCap}, ' Return of ',
      { text: '     ', background: returnColor},
      ` Font of ${fontFamily}, and FontSize of ${fontSize / 10}"`,
    ]})
  })
  console.log('ELements IDs:', elementsIDs.join(', '))

  let headerText = ['Vizorteam']

  if (isAuthenticated) {
    headerText.push(...[' | ', 'Total Cost: $', price])
  }

  var docDefinition = {
    pageOrientation: 'portrait',
    pageMargins: [ 40, 60, 40, 60 ],
    footer: {
      columns: [
        // { text: ['Vizorteam | The Sign Guyz', ' | ', 'Total Cost: $', price], alignment: 'center' }
      ]
    },

    content: [
      { text: 'Summary: Sign Builder Report', style: 'header' },
      { text: headerText, alignment: 'center', color: '#0f766b' },
      {
        // if you specify both width and height - image will be stretched
        image: img,
        fit: [500, 500],
        alignment: 'center'
      },
      'Elements details:',
      {
        // for numbered lists set the ol key
        ol: items
      },
    ],

    styles: {
      header: {
        bold: true,
        fontSize: 22,
        alignment: 'center',
      }
    }
};
  const x = pdfMake.createPdf(docDefinition);
  x.open();
}

