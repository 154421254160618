import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Intent } from '@blueprintjs/core';

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (!isAuthenticated &&
    <Button intent={Intent.SUCCESS} onClick={() => loginWithRedirect()} text="Log In" />
  )
};

export default LoginButton;




