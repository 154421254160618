import React from 'react';
import { PolotnoContainer, WorkspaceWrap } from 'polotno';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { Workspace } from 'polotno/canvas/workspace';

import { SomeAppComponent } from './components/pricing-component';

import { loadFile } from './file';
import AppToolbar from './components/app-toolbar';
import { useAuth0 } from "@auth0/auth0-react";
import { createSampleComponents } from './utils/ziyad-functions';
import { SignStudioSidePanel } from './components';
import { getConfig } from "./config";
import PuffLoader from "react-spinners/PuffLoader";
import { useState } from "react";
import { css } from "@emotion/react";

const config = getConfig();

const useHeight = () => {
  const [height, setHeight] = React.useState(window.innerHeight);
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight);
    });
  }, []);
  return height;
};

const override = css`
  display: block;
  margin: auto;
  border-color: red;
  background: red;
`;

const App = ({ store }) => {

  const { user, getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();
  const domain = config.domain;

  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("red");
  // console.log('s', loading)

  if (!store.pages.length) {
    store.addPage();
  }


  if (!isAuthenticated && loading) setLoading(false);

  // load state from user_metadata if user has authenticated
  if (isAuthenticated && !store?.activePage?.custom?.updated) {
    setLoading(true)
    // console.log('done')
    store?.activePage?.set({ custom: { updated: true }})

    getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scopes: "read:current_user",
    }).then(accessToken => {
      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

      fetch(userDetailsByIdUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).then(metadataResponse => {

        metadataResponse.json().then( response => {
          const stateJson = response?.user_metadata?.state;

          if (stateJson) {
            // console.log(loading)
            // console.log('triggered', stateJson)
            store.loadJSON(stateJson, true);
            setLoading(false);
          }
        });
      });
    });
  };

  // load sample components if user is not authenticted so they can interact with the application
  if (!isAuthenticated && !store?.activePage?.custom?.elementsCreated) {
    console.log('hereee')
    createSampleComponents(store)
    store?.activePage?.set({ custom: { elementsCreated: true }})
  }

  const handleDrop = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();
          loadFile(file, store);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        loadFile(ev.dataTransfer.files[i], store);
      }
    }
  };

  const height = useHeight();

  return (
    <div
      style={{
        width: '100vw',
        height: height + 'px',
        display: 'flex',
        flexDirection: 'column',
      }}
      onDrop={handleDrop}
    >
      <div style={{ height: 'calc(100% - 50px)' }}>
        <PolotnoContainer className="polotno-app-container">
          <SignStudioSidePanel store={store} />
        <WorkspaceWrap>
          <AppToolbar store={store} />
          <Workspace pageControlsEnabled={false} store={store} />
          {isAuthenticated && <ZoomButtons store={store} />}
          {isAuthenticated && <SomeAppComponent store={store} />}
        </WorkspaceWrap>
        </PolotnoContainer>
        {loading && <div style={
        {
            position: 'fixed',
            padding:0,
            margin:0,

            top:0,
            left: '150px',

            display: 'flex',
            width: '100vw',
            height: 'calc(100% - 50px)',
            background: 'red'
        }}>
          <PuffLoader color={color} loading={loading} css={override} size={150} />
        </div>}
      </div>
    </div>
  );
};

export default App;
